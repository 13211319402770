import React from 'react';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

const MapPin = styled(Avatar)`
  background: #ffb19d;
  border: 2px solid crimson;
  border-radius: 30px;
  width: ${(props) => (props.zoomLevel > 8 ? '25px' : '15px')};
  height: ${(props) => (props.zoomLevel > 8 ? '25px' : '15px')};
  > button {
    color: #ffb19d;
  }
  :hover,
  :focus {
    ${(props) => (props.isMobile ? 'bottom: 75px' : '')};
    ${(props) => (props.isMobile ? 'right: 50px' : '')};
    background: white;
    border: 2px solid #866d9e;
    width: 55px;
    height: 55px;
    z-index: 99;
    > button {
      color: #3f51b5;
    }
  }
`;

const withMapPin = (Component) => (props) => (
  <MapPin zoomLevel={props.zoomLevel} isMobile={props.isMobile}>
    <Component
      customButtonStyle={{
        fontSize: '10px',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        hoverColor: 'white',
      }}
      {...props}
    />
  </MapPin>
);

export default withMapPin;
