import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'venice';

const header = (date) => ({
  heading: 'Venice, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '6/22/2019',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `After a couple weekends with you in SF you asked what I had
          planned for my birthday. I decided to fly down and celebrate
          with you and my friend Austin. We started at "The" Waterfront!`,
        backgroundColor: 'pink',
      }),
      ...['VENICE_3.jpg', 'VENICE_MOV_1.mp4'].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('6/22/2019'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '10/5/2019',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `On October 26th I flew down to LA and we stayed in another cute
          AirBnB. When I got there you I found you had surprised me with a
          book and a few special polaroids of us.`,
        backgroundColor: 'pink',
      }),
      ...['VENICE_1.jpg', 'VENICE_2.jpg', 'VENICE_4.jpg'].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('10/5/2019'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '6/13/2020',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `Guess who moved to Venice two weeks before this?? And we got bikes!`,
        backgroundColor: 'pink',
      }),
      ...[
        'VENICE_5.jpg',
        'VENICE_6.jpg',
        'VENICE_7.jpg',
        'VENICE_8.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('6/13/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '9/19/2020',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `Just another weekend in Venice...`,
        backgroundColor: 'pink',
      }),
      ...[
        { fileName: 'VENICE_9.jpg', seeMore: 'Jenga champ!' },
        { fileName: 'VENICE_10.jpg' },
        {
          fileName: 'VENICE_11.jpg',
          seeMore: 'My first time at the Venice canals',
        },
        { fileName: 'VENICE_12.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('9/19/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '10/20/2020',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `Date weekend! The result of our putt putt bet...`,
        backgroundColor: 'pink',
      }),
      ...[
        { fileName: 'VENICE_21.jpg' },
        { fileName: 'VENICE_22.jpg' },
        { fileName: 'VENICE_13.jpg' },
        { fileName: 'VENICE_14.jpg' },
        { fileName: 'VENICE_15.jpg' },
        { fileName: 'VENICE_16.jpg' },
        { fileName: 'VENICE_17.jpg' },
        { fileName: 'VENICE_18.jpg' },
        { fileName: 'VENICE_19.jpg' },
        { fileName: 'VENICE_20.jpg' },
        {
          fileName: 'VENICE_MOV_2.mp4',
          seeMore: 'Your favorite song! Dancing queen in my favorite onesie',
        },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('10/20/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '12/20/2020',
    stories: [
      buildIntro({
        title: 'Venice, CA',
        description: `Christmas trees and hot cocoa`,
        backgroundColor: 'pink',
      }),
      ...[
        { fileName: 'VENICE_23.jpg' },
        { fileName: 'VENICE_24.jpg' },
        { fileName: 'VENICE_25.jpg' },
        { fileName: 'VENICE_26.jpg' },
        { fileName: 'VENICE_27.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('12/20/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 33.985,
    lng: -118.4695,
  },
  trips,
};
