import { buildIntro, buildStory } from '../../../utils';

const location = 'topangaCanyon';
const header = {
  heading: 'Topanga Canyon, CA',
  subheading: 'February 14-16th, 2020',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '2/14/2020',
    stories: [
      buildIntro({
        title: 'Topanga Canyon, CA',
        description: `Our romantic lil Valentine's Day getaway in our 2nd lil tiny home ❤️`,
        backgroundColor: 'pink',
      }),
      ...[
        {
          fileName: 'TOPANGA_CANYON_MOV_1.mp4',
        },
        { fileName: 'TOPANGA_CANYON_1.jpg' },
        { fileName: 'TOPANGA_CANYON_2.jpg' },
        { fileName: 'TOPANGA_CANYON_3.jpg' },
        { fileName: 'TOPANGA_CANYON_4.jpg' },
        { fileName: 'TOPANGA_CANYON_5.jpg' },
        { fileName: 'TOPANGA_CANYON_6.jpg', seeMore: 'Norma!' },
        { fileName: 'TOPANGA_CANYON_7.jpg' },
        { fileName: 'TOPANGA_CANYON_8.jpg' },
        { fileName: 'TOPANGA_CANYON_9.jpg' },
        { fileName: 'TOPANGA_CANYON_10.jpg' },
        { fileName: 'TOPANGA_CANYON_11.jpg', seeMore: 'Hiker babe!' },
        { fileName: 'TOPANGA_CANYON_12.jpg' },
        { fileName: 'TOPANGA_CANYON_13.jpg' },
        { fileName: 'TOPANGA_CANYON_14.jpg' },
        { fileName: 'TOPANGA_CANYON_15.jpg' },
        {
          fileName: 'TOPANGA_CANYON_MOV_2.mp4',
          seeMore: 'My dancing queen hahah',
        },
        { fileName: 'TOPANGA_CANYON_16.jpg' },
        { fileName: 'TOPANGA_CANYON_17.jpg' },
        {
          fileName: 'TOPANGA_CANYON_18.jpg',
          seeMore: 'I love you lil baked potato',
        },
        { fileName: 'TOPANGA_CANYON_19.jpg' },
        { fileName: 'TOPANGA_CANYON_20.jpg' },
        { fileName: 'TOPANGA_CANYON_21.jpg' },
        { fileName: 'TOPANGA_CANYON_22.jpg' },
        { fileName: 'TOPANGA_CANYON_23.jpg', seeMore: 'gooof' },
        { fileName: 'TOPANGA_CANYON_24.jpg' },
        { fileName: 'TOPANGA_CANYON_25.jpg' },
        { fileName: 'TOPANGA_CANYON_26.jpg' },
        {
          fileName: 'TOPANGA_CANYON_27.jpg',
          seeMore:
            'The absolute dream I love cooking breakfast for you bb take me back!!!',
        },
        {
          fileName: 'TOPANGA_CANYON_28.jpg',
          seeMore: 'The first set of the film camera pictures!',
        },
        {
          fileName: 'TOPANGA_CANYON_29.jpg',
          seeMore: 'Apparently your favorite photo of me...',
        },
        { fileName: 'TOPANGA_CANYON_30.jpg' },
        { fileName: 'TOPANGA_CANYON_31.jpg' },
        { fileName: 'TOPANGA_CANYON_32.jpg' },
        { fileName: 'TOPANGA_CANYON_33.jpg' },
        { fileName: 'TOPANGA_CANYON_34.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
  {
    date: '5/11/2020',
    stories: [
      buildIntro({
        title: 'Topanga Canyon, CA',
        description: `Round 2! Our week long quarantine in Paolo's Ladder`,
        backgroundColor: 'pink',
      }),
      ...[
        { fileName: 'TOPANGA_CANYON_35.jpg' },
        { fileName: 'TOPANGA_CANYON_36.jpg' },
        { fileName: 'TOPANGA_CANYON_37.jpg' },
        { fileName: 'TOPANGA_CANYON_38.jpg' },
        { fileName: 'TOPANGA_CANYON_39.jpg' },
        { fileName: 'TOPANGA_CANYON_40.jpg', seeMore: 'Norma!' },
        { fileName: 'TOPANGA_CANYON_41.jpg' },
        { fileName: 'TOPANGA_CANYON_42.jpg' },
        { fileName: 'TOPANGA_CANYON_43.jpg' },
        { fileName: 'TOPANGA_CANYON_44.jpg' },
        { fileName: 'TOPANGA_CANYON_45.jpg', seeMore: 'Hiker babe!' },
        { fileName: 'TOPANGA_CANYON_46.jpg' },
        { fileName: 'TOPANGA_CANYON_47.jpg' },
        { fileName: 'TOPANGA_CANYON_48.jpg' },
        { fileName: 'TOPANGA_CANYON_49.jpg' },
        { fileName: 'TOPANGA_CANYON_50.jpg' },
        { fileName: 'TOPANGA_CANYON_51.jpg' },
        { fileName: 'TOPANGA_CANYON_52.jpg' },
        { fileName: 'TOPANGA_CANYON_53.jpg' },
        { fileName: 'TOPANGA_CANYON_54.jpg' },
        { fileName: 'TOPANGA_CANYON_55.jpg' },
        { fileName: 'TOPANGA_CANYON_56.jpg' },
        { fileName: 'TOPANGA_CANYON_57.jpg' },
        { fileName: 'TOPANGA_CANYON_58.jpg' },
        {
          fileName: 'TOPANGA_CANYON_MOV_3.mp4',
          seeMore: 'The hidden tunnel!!',
        },
        {
          fileName: 'TOPANGA_CANYON_MOV_4.mp4',
          seeMore: 'The hidden tunnel!!',
        },
        { fileName: 'TOPANGA_CANYON_59.jpg' },
        { fileName: 'TOPANGA_CANYON_60.jpg' },
        { fileName: 'TOPANGA_CANYON_61.jpg' },
        { fileName: 'TOPANGA_CANYON_62.jpg' },
        { fileName: 'TOPANGA_CANYON_63.jpg' },
        { fileName: 'TOPANGA_CANYON_64.jpg' },
        { fileName: 'TOPANGA_CANYON_65.jpg' },
        { fileName: 'TOPANGA_CANYON_66.jpg' },
        { fileName: 'TOPANGA_CANYON_67.jpg' },
        { fileName: 'TOPANGA_CANYON_68.jpg' },
        { fileName: 'TOPANGA_CANYON_69.jpg' },
        { fileName: 'TOPANGA_CANYON_70.jpg' },
        { fileName: 'TOPANGA_CANYON_71.jpg' },
        { fileName: 'TOPANGA_CANYON_72.jpg' },
        { fileName: 'TOPANGA_CANYON_73.jpg' },
        { fileName: 'TOPANGA_CANYON_74.jpg' },
        { fileName: 'TOPANGA_CANYON_75.jpg' },
        { fileName: 'TOPANGA_CANYON_76.jpg' },
        { fileName: 'TOPANGA_CANYON_77.jpg' },
        { fileName: 'TOPANGA_CANYON_78.jpg' },
        { fileName: 'TOPANGA_CANYON_79.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          {
            heading: 'Topanga Canyon, CA',
            subheading: 'May 11-18th, 2020',
          },
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0919,
    lng: -118.6021,
  },
  trips,
};
