import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'lakeTahoe';

const header = (date) => ({
  heading: 'Lake Tahoe, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '7/23/2020',
    stories: [
      buildIntro({
        title: 'Lake Tahoe, CA',
        description: `Friends trip to Lake Tahoe!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'LAKE_TAHOE_1.jpg',
        'LAKE_TAHOE_2.jpg',
        'LAKE_TAHOE_3.jpg',
        'LAKE_TAHOE_4.jpg',
        'LAKE_TAHOE_5.jpg',
        'LAKE_TAHOE_6.jpg',
        'LAKE_TAHOE_7.jpg',
        'LAKE_TAHOE_8.jpg',
        'LAKE_TAHOE_9.jpg',
        'LAKE_TAHOE_10.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('7/23/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 39.2269,
    lng: -120.0819,
  },
  trips,
};
