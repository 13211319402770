import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'bigSur';

const header = (date) => ({
  heading: 'Big Sur, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '9/14/19',
    stories: [
      buildIntro({
        title: 'Big Sur, CA',
        description:
          'On September 14th we left Carmel for the day and drove down PCH to Big Sur ⛰🌊',
        backgroundColor: 'lightblue',
      }),
      ...[
        'BIG_SUR_9.jpg',
        'BIG_SUR_1.jpg',
        'BIG_SUR_10.jpg',
        'BIG_SUR_3.jpg',
        'BIG_SUR_5.jpg',
        'BIG_SUR_4.jpg',
        'BIG_SUR_6.jpg',
        'BIG_SUR_7.jpg',
        'BIG_SUR_8.jpg',
        'BIG_SUR_2.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('9/14/2019'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '3/6/20',
    stories: [
      buildIntro({
        title: 'Big Sur, CA',
        description: `On March 6th we met back in Big Sur at Pfeiffer Beach for Kath's and Liz's birthday. It was our first true tent camping trip together, and of course no better way to start it off than in the rain`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'BIG_SUR_11.jpg',
        'BIG_SUR_12.jpg',
        'BIG_SUR_13.jpg',
        'BIG_SUR_14.jpg',
        'BIG_SUR_15.jpg',
        'BIG_SUR_16.jpg',
        'BIG_SUR_17.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('3/6/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '1/6/21',
    stories: [
      buildIntro({
        title: 'Big Sur, CA',
        description: `Last stop on the NYE road trip! A couple nights in Palo Colorado and another at Deetjen's.`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'BIG_SUR_18.jpg',
        'BIG_SUR_19.jpg',
        'BIG_SUR_20.jpg',
        'BIG_SUR_21.jpg',
        'BIG_SUR_22.jpg',
        'BIG_SUR_23.jpg',
        'BIG_SUR_24.jpg',
        'BIG_SUR_25.jpg',
        'BIG_SUR_26.jpg',
        'BIG_SUR_27.jpg',
        'BIG_SUR_28.jpg',
        'BIG_SUR_29.jpg',
        'BIG_SUR_30.jpg',
        'BIG_SUR_31.jpg',
        'BIG_SUR_32.jpg',
        'BIG_SUR_33.jpg',
        'BIG_SUR_34.jpg',
        'BIG_SUR_35.jpg',
        'BIG_SUR_36.jpg',
        'BIG_SUR_37.jpg',
        'BIG_SUR_38.jpg',
        'BIG_SUR_39.jpg',
        'BIG_SUR_40.jpg',
        'BIG_SUR_41.jpg',
        'BIG_SUR_42.jpg',
        'BIG_SUR_43.jpg',
        'BIG_SUR_44.jpg',
        'BIG_SUR_45.jpg',
        'BIG_SUR_46.jpg',
        'BIG_SUR_47.jpg',
        'BIG_SUR_48.jpg',
        'BIG_SUR_49.jpg',
        'BIG_SUR_50.jpg',
        'BIG_SUR_51.jpg',
        'BIG_SUR_52.jpg',
        'BIG_SUR_53.jpg',
        'BIG_SUR_54.jpg',
        'BIG_SUR_55.jpg',
        'BIG_SUR_56.jpg',
        'BIG_SUR_57.jpg',
        'BIG_SUR_58.jpg',
        'BIG_SUR_59.jpg',
        'BIG_SUR_60.jpg',
        'BIG_SUR_61.jpg',
        'BIG_SUR_62.jpg',
        'BIG_SUR_63.jpg',
        'BIG_SUR_64.jpg',
        'BIG_SUR_65.jpg',
        'BIG_SUR_66.jpg',
        'BIG_SUR_67.jpg',
        'BIG_SUR_68.jpg',
        'BIG_SUR_69.jpg',
        'BIG_SUR_70.jpg',
        'BIG_SUR_71.jpg',
        'BIG_SUR_72.jpg',
        'BIG_SUR_73.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('1/6/2021'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 36.2704,
    lng: -121.8081,
  },
  trips,
};
