import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${(props) => props.backgroundColor || 'lightblue'};
  padding: 20px;
  text-align: center;
  min-height: 500px;
`;

const Title = styled.h1`
  margin-top: 100px;
`;
const Description = styled.h2`
  margin-top: 5px;
`;

const ContentStory = (props) => {
  const { title, description, backgroundColor } = props;

  return (
    <Container backgroundColor={backgroundColor}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default ContentStory;
