import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'cedarBloom';

const header = (date) => ({
  heading: 'Cedar Bloom, OR',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '1/3/21',
    stories: [
      buildIntro({
        title: 'Cedar Bloom, OR',
        description: `Stop #3 on our New Year's road trip. That sauna though...`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'CEDAR_BLOOM_1.jpg',
        'CEDAR_BLOOM_2.jpg',
        'CEDAR_BLOOM_3.jpg',
        'CEDAR_BLOOM_4.jpg',
        'CEDAR_BLOOM_5.jpg',
        'CEDAR_BLOOM_6.jpg',
        'CEDAR_BLOOM_7.jpg',
        'CEDAR_BLOOM_8.jpg',
        'CEDAR_BLOOM_9.jpg',
        'CEDAR_BLOOM_10.jpg',
        'CEDAR_BLOOM_11.jpg',
        'CEDAR_BLOOM_12.jpg',
        'CEDAR_BLOOM_13.jpg',
        'CEDAR_BLOOM_14.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('1/3/2021'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 42.1741,
    lng: -123.6643,
  },
  trips,
};
