import { buildIntro, buildStory } from '../../../utils';

const location = 'pointReyes';

const header = {
  heading: 'Point Reyes, CA',
  subheading: 'October 10th-13th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '10/11/19',
    stories: [
      buildIntro({
        title: 'Point Reyes, CA',
        description: `On October 10th we drove up to Point Reyes to celebrate
          Allison's dad's and sister's birthday. We stayed in an
          incredible treehouse nestled into the hillside.`,
        backgroundColor: '#779677',
      }),
      ...[
        { fileName: 'POINT_REYES_1.jpg' },
        { fileName: 'POINT_REYES_2.jpg' },
        {
          fileName: 'POINT_REYES_3.jpg',
          seeMore: 'A candid photo that I love, props to your sister!',
        },
        { fileName: 'POINT_REYES_4.jpg' },
        { fileName: 'POINT_REYES_5.jpg' },
        { fileName: 'POINT_REYES_6.jpg' },
        { fileName: 'POINT_REYES_7.jpg' },
        { fileName: 'POINT_REYES_23.jpg' },
        { fileName: 'POINT_REYES_8.jpg' },
        { fileName: 'POINT_REYES_9.jpg', seeMore: 'BOOOO!! 👻' },
        { fileName: 'POINT_REYES_10.jpg' },
        { fileName: 'POINT_REYES_12.jpg' },
        { fileName: 'POINT_REYES_11.jpg' },
        {
          fileName: 'POINT_REYES_14.jpg',
          seeMore: 'Ah do I miss this tree house!',
        },
        { fileName: 'POINT_REYES_15.jpg', seeMore: 'Spying on me!' },
        { fileName: 'POINT_REYES_16.jpg' },
        { fileName: 'POINT_REYES_17.jpg' },
        { fileName: 'POINT_REYES_21.jpg' },
        { fileName: 'POINT_REYES_20.jpg' },
        { fileName: 'POINT_REYES_13.jpg' },
        { fileName: 'POINT_REYES_18.jpg' },
        { fileName: 'POINT_REYES_19.jpg' },
        { fileName: 'POINT_REYES_22.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 38.0682,
    lng: -122.8042,
  },
  trips,
};
