const cookieName = 'adventure.am.token';

export const isBrowser = () => typeof window !== undefined;

export const getUser = () =>
  isBrowser() ? atob(window.localStorage.getItem(cookieName)) : false;

export const setUser = (user) => {
  const token = Buffer.from(JSON.stringify(user)).toString('base64');
  window.localStorage.setItem(cookieName, btoa(token));
};

const secureUser = {
  un: 'bakedpotato',
  pw: 'squash',
};

const hashedUser =
  'eyJ1biI6ImJha2VkcG90YXRvIiwibmFtZSI6ImFkbWluIiwiZW1haWwiOiJhZG1pbkBhZG1pbi5jb20ifQ==';

export const handleLogin = ({ un, pw }) =>
  un === secureUser.un && pw === secureUser.pw
    ? setUser({
        un,
        name: 'admin',
        email: 'admin@admin.com',
      })
    : false;

export const isLoggedIn = () => getUser() === hashedUser;

export const logout = (callback) => {
  setUser({});
  // anything extra consumer integration wants to add post-logout
  callback();
};
