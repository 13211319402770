import React from 'react';

import SeeMore from '../../../components/SeeMore';
import { buildIntro, buildStory } from '../../../utils';

const location = 'hollywood';

const header = {
  heading: 'Hollywood, CA',
  subheading: 'November 2nd-3rd, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '11/2/2019',
    stories: [
      buildIntro({
        title: 'Hollywood, CA',
        description:
          "On November 2nd we had mimosa's in Venice before driving to stay at the Golddigger hotel in Hollywood 🎸🎥",
        backgroundColor: 'lightblue',
        seeMore: ({ close }) => (
          <SeeMore
            close={close}
            text={'It was the first time I saw you really drunk!'}
          />
        ),
      }),
      ...[
        'HOLLYWOOD_1.jpg',
        'HOLLYWOOD_3.jpg',
        'HOLLYWOOD_4.jpg',
        'HOLLYWOOD_5.jpg',
        'HOLLYWOOD_6.jpg',
        'HOLLYWOOD_7.jpg',
        'HOLLYWOOD_8.jpg',
        'HOLLYWOOD_2.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0983,
    lng: -118.315,
  },
  trips,
};
