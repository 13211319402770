import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'baypointLanding';

const header = (date) => ({
  heading: 'Bay Point Landing, OR',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '1/4/21',
    stories: [
      buildIntro({
        title: 'Bay Point Landing, OR',
        description: `Stop #4 on our New Year's road trip!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'BAYPOINT_LANDING_1.jpg',
        'BAYPOINT_LANDING_2.jpg',
        'BAYPOINT_LANDING_3.jpg',
        'BAYPOINT_LANDING_4.jpg',
        'BAYPOINT_LANDING_5.jpg',
        'BAYPOINT_LANDING_6.jpg',
        'BAYPOINT_LANDING_7.jpg',
        'BAYPOINT_LANDING_8.jpg',
        'BAYPOINT_LANDING_9.jpg',
        'BAYPOINT_LANDING_10.jpg',
        'BAYPOINT_LANDING_11.jpg',
        'BAYPOINT_LANDING_12.jpg',
        'BAYPOINT_LANDING_13.jpg',
        'BAYPOINT_LANDING_14.jpg',
        'BAYPOINT_LANDING_15.jpg',
        'BAYPOINT_LANDING_16.jpg',
        'BAYPOINT_LANDING_17.jpg',
        'BAYPOINT_LANDING_18.jpg',
        'BAYPOINT_LANDING_19.jpg',
        'BAYPOINT_LANDING_20.jpg',
        'BAYPOINT_LANDING_21.jpg',
        'BAYPOINT_LANDING_22.jpg',
        'BAYPOINT_LANDING_23.jpg',
        'BAYPOINT_LANDING_24.jpg',
        'BAYPOINT_LANDING_25.jpg',
        'BAYPOINT_LANDING_26.jpg',
        'BAYPOINT_LANDING_27.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('1/4/2021'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 43.37271,
    lng: -124.29157,
  },
  trips,
};
