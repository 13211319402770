import React from 'react';
import SeeMore from '../components/SeeMore';
import ImageStory from '../components/ImageStory';

const isVideo = (fileName) => fileName.indexOf('mp4') > -1;

const getFluidImage = (fileName, imageEdges) =>
  imageEdges.find((edge) => edge.node.Key.includes(fileName)).node
    .childImageSharp.fluid;

const buildClassic = (
  location,
  fileName,
  header,
  seeMore,
  duration = 5000,
  imageEdges,
) => ({
  url: isVideo(fileName)
    ? `https://d3ddiq3mrjpsjx.cloudfront.net/${location}/${fileName}`
    : getFluidImage(fileName, imageEdges).src,
  header,
  type: isVideo(fileName) ? 'video' : null,
  seeMore: seeMore && (({ close }) => <SeeMore close={close} text={seeMore} />),
  duration: isVideo(fileName) ? null : duration,
});

const buildStory = (
  location,
  fileName,
  header,
  isMobile,
  imageEdges,
  seeMore,
  duration = 5000,
) =>
  isVideo(fileName) || !isMobile
    ? buildClassic(location, fileName, header, seeMore, duration, imageEdges)
    : {
        header,
        content: () => (
          <ImageStory src={getFluidImage(fileName, imageEdges).src} />
        ),
        seeMore:
          seeMore && (({ close }) => <SeeMore close={close} text={seeMore} />),
        duration,
      };

export default buildStory;
