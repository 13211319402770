import React from 'react';
import ContentStory from '../components/ContentStory';

const buildIntro = ({
  title,
  description,
  backgroundColor,
  seeMore,
  duration = 5000,
}) => ({
  content: () => (
    <ContentStory
      title={title}
      description={description}
      backgroundColor={backgroundColor}
    />
  ),
  seeMore,
  duration,
});

export default buildIntro;
