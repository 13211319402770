import { buildIntro, buildStory } from '../../../utils';

const location = 'yosemite';

const header = {
  heading: 'Yosemite, CA',
  subheading: 'July 28th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '7/28/2019',
    stories: [
      buildIntro({
        title: 'Yosemite, CA',
        description: `The adventure that started it all! On July 28th you drove up from LA so that we could go to
          Yosemite together. We ended up a few miles short but it was our
          first adventure of many and a sign for all the amazing things to
          come.`,
        backgroundColor: '#72a975',
      }),
      ...[
        { fileName: 'YOSEMITE_2.jpg' },
        { fileName: 'YOSEMITE_3.jpg' },
        {
          fileName: 'YOSEMITE_1.jpg',
          seeMore:
            "The only gold I found this weekend was you ;) (how's that for a line!)",
        },
        { fileName: 'YOSEMITE_4.jpg' },
        {
          fileName: 'YOSEMITE_MOV_1.mp4',
          seeMore:
            'Nothing like being stuck in a car dealership in the middle of nowhere for hours waiting for a tow truck to come take us a few hours away!',
        },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 37.9533,
    lng: -120.4227,
  },
  trips,
};
