import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'catalina';

const header = (date) => ({
  heading: 'Catalina, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '7/4/2020',
    stories: [
      buildIntro({
        title: 'Catalina, CA',
        description: `4th of July Weekend! My first time on Catalina with you 🚤🏝.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        { fileName: 'CATALINA_1.jpg' },
        { fileName: 'CATALINA_2.jpg' },
        { fileName: 'CATALINA_3.jpg' },
        { fileName: 'CATALINA_MOV_1.mp4' },
        { fileName: 'CATALINA_4.jpg' },
        { fileName: 'CATALINA_5.jpg' },
        {
          fileName: 'CATALINA_6.jpg',
          seeMore:
            'Babe gets a hole in 1! (pretty sure I also got a hole in 1 but can/t find the pic...',
        },
        { fileName: 'CATALINA_7.jpg' },
        {
          fileName: 'CATALINA_8.jpg',
          seeMore: 'Check out that boooty!',
        },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('7/4/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '8/29/2020',
    stories: [
      buildIntro({
        title: 'Catalina, CA',
        description: `End of August Weekend! My first time on Catalina with you 🚤🏝.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        {
          fileName: 'CATALINA_9.jpg',
          seeMore: "The first fish I've seen you catch!",
        },
        { fileName: 'CATALINA_MOV_2.mp4' },
        { fileName: 'CATALINA_10.jpg' },
        {
          fileName: 'CATALINA_11.jpg',
          seeMore: 'The boys take over Catalina',
        },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('8/29/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '10/13/2020',
    stories: [
      buildIntro({
        title: 'Catalina, CA',
        description: `Chelsea's 30th!`,
        backgroundColor: '#f5d142',
      }),
      ...[
        { fileName: 'CATALINA_12.jpg' },
        { fileName: 'CATALINA_24.jpg' },
        { fileName: 'CATALINA_13.jpg' },
        { fileName: 'CATALINA_14.jpg' },
        { fileName: 'CATALINA_15.jpg' },
        { fileName: 'CATALINA_16.jpg' },
        { fileName: 'CATALINA_MOV_3.mp4', seeMore: '"Tennis"' },
        { fileName: 'CATALINA_MOV_4.mp4', seeMore: "That's more like it" },
        { fileName: 'CATALINA_MOV_6.mp4', seeMore: "Creepin'" },
        { fileName: 'CATALINA_17.jpg' },
        { fileName: 'CATALINA_18.jpg', seeMore: 'Family shots!' },
        { fileName: 'CATALINA_19.jpg' },
        { fileName: 'CATALINA_20.jpg' },
        { fileName: 'CATALINA_21.jpg' },
        { fileName: 'CATALINA_22.jpg' },
        { fileName: 'CATALINA_23.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('10/13/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 33.3428,
    lng: -118.3282,
  },
  trips,
};
