import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: auto;
  maxwidth: 100%;
  height: auto;
  margin: auto;
`;

const ImageStory = (props) => {
  const { src } = props;

  return <Image src={src} />;
};

export default ImageStory;
