import React, { useState } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  Button,
} from '@material-ui/core';

import { handleLogin, isLoggedIn, getUser } from '../services/auth';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 32px auto;
  min-width: 300px;
`;

const SubmitButton = styled(Button)`
  background-color: #866d9e;
  color: white;
  margin-top: 24px;
  :hover {
    background-color: #72499a;
  }
  :disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

const ErrorMessage = styled(FormHelperText)`
  color: #f44336;
  margin: 16px auto;
`;

const Login = () => {
  if (isLoggedIn()) {
    navigate('/');
  }

  const user = getUser() || {};
  const [username, setUsername] = useState(user.un);
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin({ un: username, pw: password });

    if (isLoggedIn()) {
      setError('');
      navigate('/');
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <FormContainer method="post" onSubmit={handleSubmit}>
      <FormControl margin="normal" required>
        <InputLabel htmlFor="username">Username</InputLabel>
        <Input
          id="username"
          error={!!error}
          aria-describedby="username"
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
        />
      </FormControl>

      <FormControl margin="normal" required>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          id="password"
          type="password"
          error={!!error}
          aria-describedby="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
        />
      </FormControl>

      <SubmitButton type="submit" variant="contained" disabled={!!error}>
        Log in
      </SubmitButton>
      {error && <ErrorMessage id="error">{error}</ErrorMessage>}
    </FormContainer>
  );
};

export default Login;
