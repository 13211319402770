import React, { useState } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import StoryModal from './StoryModal';
import { splitLocationName } from '../utils';

const FilterInput = styled(TextField)`
  margin: 12px 0 8px 1.0875rem;
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 75%;
  }
`;

const NoResultsText = styled(Typography)`
  margin: 12px 0 8px 1.0875rem;
`;

const getYearHeaderIndex = (year, adventures) =>
  adventures.findIndex((date) => moment(date).format('YYYY') === year);

const getAdventuresByDate = (adventures, isMobile, imageEdges) =>
  adventures.reduce((adventuresByDate, adventure) => {
    adventure.trips(isMobile, imageEdges).forEach((trip) => {
      adventuresByDate[trip.date]
        ? adventuresByDate[trip.date].push({
            location: adventure.location,
            stories: trip.stories,
          })
        : (adventuresByDate[trip.date] = [
            {
              location: adventure.location,
              stories: trip.stories,
            },
          ]);
    });
    return adventuresByDate;
  }, {});

const ListView = (props) => {
  const { adventures, isMobile, imageEdges, onFilterInput } = props;

  const adventuresByDate = getAdventuresByDate(
    adventures,
    isMobile,
    imageEdges,
  );
  const sortedAdventuresByDate = Object.keys(adventuresByDate).sort(
    (a, b) => moment(b).format('x') - moment(a).format('x'),
  );
  const header2019Index = getYearHeaderIndex('2019', sortedAdventuresByDate);
  const header2020Index = getYearHeaderIndex('2020', sortedAdventuresByDate);

  return (
    <>
      <FilterInput
        label="Search location"
        onChange={(event) => onFilterInput(event.target.value.trim())}
      />
      {sortedAdventuresByDate.length ? (
        sortedAdventuresByDate.map((date, index) => (
          <div style={{ margin: '10px 1.0875rem' }}>
            {header2019Index === index || header2020Index === index ? (
              <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                {moment(date).format('YYYY')}
              </Typography>
            ) : null}
            <Typography variant="subtitle2">
              {moment(date).format('MMMM Do')}
            </Typography>
            {adventuresByDate[date].map((adventure) => (
              <StoryModal
                location={splitLocationName(adventure.location)}
                stories={adventure.stories}
              />
            ))}
          </div>
        ))
      ) : (
        <NoResultsText variant="body2">Haven't been here yet!</NoResultsText>
      )}
    </>
  );
};

export default ListView;
