import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';

import withMapPin from './MapPin';
import StoryModal from './StoryModal';
import { splitLocationName, combineTripStories } from '../utils';

const StoryModalWithMapPin = withMapPin(StoryModal);
const GOOGLE_MAPS_KEY = 'AIzaSyDAH1ZDsoybf3ctu66dLQ0eX9N-zO1B_Tw';

const MapView = (props) => {
  const { isMobile, adventures, imageEdges } = props;
  const [zoomLevel, setZoomLevel] = useState(4);
  const [lng, setLng] = useState(-96.5308);

  useEffect(() => {
    if (isMobile) {
      setZoomLevel(6);
      setLng(-119.5308);
    }
  }, [isMobile]);

  const defaultMap = {
    center: { lat: 36, lng },
    zoom: zoomLevel,
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
      defaultCenter={defaultMap.center}
      defaultZoom={defaultMap.zoom}
      onZoomAnimationEnd={(zoomLevel) => setZoomLevel(zoomLevel)}
    >
      {adventures.map((adventure) => (
        <StoryModalWithMapPin
          location={splitLocationName(adventure.location)}
          stories={combineTripStories(adventure.trips(isMobile, imageEdges))}
          {...adventure.coordinates}
          zoomLevel={zoomLevel}
          isMobile={isMobile}
        />
      ))}
    </GoogleMapReact>
  );
};

export default MapView;
