import React from 'react';

import SeeMore from '../../../components/SeeMore';
import { buildIntro, buildStory } from '../../../utils';

const location = 'philo';

const header = {
  heading: 'Philo, CA',
  subheading: 'November 23rd, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '11/23/2019',
    stories: [
      buildIntro({
        title: 'Philo, CA',
        description: `On November 23rd we took off to the Philo 🍎 Farm!`,
        backgroundColor: 'pink',
        seeMore: ({ close }) => (
          <SeeMore
            close={close}
            text={'We met goats, farm dogs, and roosters!'}
          />
        ),
      }),
      ...[
        {
          fileName: 'PHILO_MOV_1.mp4',
          seeMore: 'Still obsessed (and impressed) with this video!',
        },
        { fileName: 'PHILO_1.jpg' },
        { fileName: 'PHILO_2.jpg' },
        { fileName: 'PHILO_8.jpg' },
        { fileName: 'PHILO_9.jpg' },
        { fileName: 'PHILO_24.jpg' },
        { fileName: 'PHILO_11.jpg' },
        { fileName: 'PHILO_30.jpg' },
        { fileName: 'PHILO_44.jpg' },
        { fileName: 'PHILO_31.jpg' },
        {
          fileName: 'PHILO_10.jpg',
          seeMore:
            'Swear this could be in a magazine or something...who took this again? ;)',
        },
        { fileName: 'PHILO_23.jpg' },
        { fileName: 'PHILO_34.jpg' },
        { fileName: 'PHILO_29.jpg' },
        { fileName: 'PHILO_41.jpg' },
        { fileName: 'PHILO_26.jpg' },
        { fileName: 'PHILO_35.jpg' },
        { fileName: 'PHILO_36.jpg' },
        { fileName: 'PHILO_21.jpg' },
        { fileName: 'PHILO_22.jpg' },
        { fileName: 'PHILO_40.jpg' },
        { fileName: 'PHILO_33.jpg' },
        { fileName: 'PHILO_32.jpg' },
        { fileName: 'PHILO_14.jpg', seeMore: 'Your goat bestie 🐐' },
        { fileName: 'PHILO_43.jpg' },
        { fileName: 'PHILO_46.jpg' },
        { fileName: 'PHILO_45.jpg' },
        { fileName: 'PHILO_15.jpg' },
        { fileName: 'PHILO_16.jpg' },
        {
          fileName: 'PHILO_17.jpg',
          seeMore:
            'This face says, "yes I just kicked your ass at ping pong how do you like me now" 🙄  ',
        },
        { fileName: 'PHILO_37.jpg' },
        { fileName: 'PHILO_18.jpg' },
        { fileName: 'PHILO_19.jpg' },
        { fileName: 'PHILO_20.jpg' },
        { fileName: 'PHILO_27.jpg' },
        { fileName: 'PHILO_28.jpg', seeMore: 'So hot right now...' },
        { fileName: 'PHILO_42.jpg' },
        { fileName: 'PHILO_6.jpg' },
        { fileName: 'PHILO_13.jpg' },
        { fileName: 'PHILO_38.jpg' },
        { fileName: 'PHILO_5.jpg' },
        { fileName: 'PHILO_3.jpg' },
        { fileName: 'PHILO_4.jpg' },
        { fileName: 'PHILO_12.jpg' },
        { fileName: 'PHILO_39.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 39.0657,
    lng: -123.445,
  },
  trips,
};
