import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'orangeCounty';

const header = (date) => ({
  heading: 'Orange County, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '11/10/19',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Home sweet home! The many times so far we've been home together,
          from our earthquake beach date, to Makena's Birthday party.`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'ORANGE_COUNTY_1.jpg',
        'ORANGE_COUNTY_2.jpg',
        'ORANGE_COUNTY_3.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('11/10/2019'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '12/8/2019',
    stories: [
      { fileName: 'ORANGE_COUNTY_4.jpg' },
      { fileName: 'ORANGE_COUNTY_5.jpg', seeMore: 'Baking away!' },
      { fileName: 'ORANGE_COUNTY_6.jpg' },
      { fileName: 'ORANGE_COUNTY_7.jpg' },
      { fileName: 'ORANGE_COUNTY_8.jpg' },
      { fileName: 'ORANGE_COUNTY_9.jpg' },
      { fileName: 'ORANGE_COUNTY_10.jpg' },
      { fileName: 'ORANGE_COUNTY_11.jpg' },
      { fileName: 'ORANGE_COUNTY_12.jpg' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('12/8/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '12/22/2019',
    stories: [
      {
        fileName: 'ORANGE_COUNTY_14.jpg',
        seeMore: 'My first time on the big red bus 🎅🏻🤶🏻',
      },
      { fileName: 'ORANGE_COUNTY_13.jpg' },
      { fileName: 'ORANGE_COUNTY_15.jpg' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('12/22/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '12/27/2019',
    stories: [
      {
        fileName: 'ORANGE_COUNTY_16.jpg',
        seeMore: 'Luxury couples massage in Laguna! Hot tub included!',
      },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('12/27/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '3/25/2020',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Ahh the start of quarantine...`,
        backgroundColor: 'lightblue',
      }),
      ...[
        {
          fileName: 'ORANGE_COUNTY_17.jpg',
        },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('3/25/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '3/31/2020',
    stories: [
      { fileName: 'ORANGE_COUNTY_MOV_1.mp4' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('3/31/2020'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '4/19/2020',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Your first birthday I got to celebrate with you! A little less extravgant this year but at least we had a nice boat ride and backyard BBQ`,
        backgroundColor: 'lightblue',
      }),
      ...[
        {
          fileName: 'ORANGE_COUNTY_18.jpg',
          seeMore: 'Birthday babe! 🎈',
        },
        { fileName: 'ORANGE_COUNTY_19.jpg' },
        { fileName: 'ORANGE_COUNTY_20.jpg' },
        { fileName: 'ORANGE_COUNTY_21.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('4/19/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '4/28/2020',
    stories: [
      { fileName: 'ORANGE_COUNTY_22.jpg' },
      { fileName: 'ORANGE_COUNTY_23.jpg' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('4/28/2020'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '9/18/2020',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Wedding time! Our first huge gathering of quarantine.`,
        backgroundColor: 'lightblue',
      }),
      ...[
        { fileName: 'ORANGE_COUNTY_22.jpg' },
        { fileName: 'ORANGE_COUNTY_23.jpg' },
        { fileName: 'ORANGE_COUNTY_24.jpg' },
        { fileName: 'ORANGE_COUNTY_25.jpg' },
        { fileName: 'ORANGE_COUNTY_26.jpg' },
        { fileName: 'ORANGE_COUNTY_28.jpg' },
        { fileName: 'ORANGE_COUNTY_29.jpg' },
        { fileName: 'ORANGE_COUNTY_30.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('9/18/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '11/26/2020',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Thanksigving time!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        { fileName: 'ORANGE_COUNTY_43.jpg' },
        { fileName: 'ORANGE_COUNTY_44.jpg' },
        { fileName: 'ORANGE_COUNTY_45.jpg' },
        { fileName: 'ORANGE_COUNTY_46.jpg' },
        { fileName: 'ORANGE_COUNTY_47.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('11/26/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '12/24/2020',
    stories: [
      buildIntro({
        title: 'Orange County, CA',
        description: `Christmas time!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        { fileName: 'ORANGE_COUNTY_31.jpg' },
        { fileName: 'ORANGE_COUNTY_32.jpg' },
        { fileName: 'ORANGE_COUNTY_33.jpg' },
        { fileName: 'ORANGE_COUNTY_34.jpg' },
        { fileName: 'ORANGE_COUNTY_35.jpg' },
        { fileName: 'ORANGE_COUNTY_36.jpg' },
        { fileName: 'ORANGE_COUNTY_37.jpg' },
        { fileName: 'ORANGE_COUNTY_38.jpg' },
        { fileName: 'ORANGE_COUNTY_39.jpg' },
        { fileName: 'ORANGE_COUNTY_40.jpg' },
        { fileName: 'ORANGE_COUNTY_41.jpg' },
        { fileName: 'ORANGE_COUNTY_42.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('12/24/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 33.5979,
    lng: -117.873,
  },
  trips,
};
