import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'santaBarbara';

const header = (date) => ({
  heading: 'Santa Barbara, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '8/14/2020',
    stories: [
      buildIntro({
        title: 'Santa Barbara, CA',
        description: `Our 1 year anniversary! Our first trip to SB together and our first stay at the Bacara`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'SANTA_BARBARA_1.jpg',
        'SANTA_BARBARA_2.jpg',
        'SANTA_BARBARA_3.jpg',
        'SANTA_BARBARA_4.jpg',
        'SANTA_BARBARA_5.jpg',
        'SANTA_BARBARA_6.jpg',
        'SANTA_BARBARA_7.jpg',
        'SANTA_BARBARA_8.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('8/14/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.4342,
    lng: -119.9202,
  },
  trips,
};
