import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'cambria';

const header = (date) => ({
  heading: 'Cambria, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '1/1/21',
    stories: [
      buildIntro({
        title: 'Cambria, CA',
        description: `Kicking off the new year with a road trip! 1st stop!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'CAMBRIA_1.jpg',
        'CAMBRIA_2.jpg',
        'CAMBRIA_3.jpg',
        'CAMBRIA_4.jpg',
        'CAMBRIA_5.jpg',
        'CAMBRIA_6.jpg',
        'CAMBRIA_7.jpg',
        'CAMBRIA_8.jpg',
        'CAMBRIA_8.jpg',
        'CAMBRIA_10.jpg',
        'CAMBRIA_11.jpg',
        'CAMBRIA_12.jpg',
        'CAMBRIA_13.jpg',
        'CAMBRIA_14.jpg',
        'CAMBRIA_15.jpg',
        'CAMBRIA_16.jpg',
        'CAMBRIA_17.jpg',
        'CAMBRIA_18.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('1/1/2021'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 35.5754,
    lng: -121.1133,
  },
  trips,
};
