import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'sanLuisObispo';

const header = (date) => ({
  heading: 'San Luis Obispo, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '11/20/20',
    stories: [
      buildIntro({
        title: 'San Luis Obispo, CA',
        description: `SLO trip and Paso Robles!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'SAN_LUIS_OBISPO_1.jpg',
        'SAN_LUIS_OBISPO_2.jpg',
        'SAN_LUIS_OBISPO_3.jpg',
        'SAN_LUIS_OBISPO_4.jpg',
        'SAN_LUIS_OBISPO_5.jpg',
        'SAN_LUIS_OBISPO_6.jpg',
        'SAN_LUIS_OBISPO_7.jpg',
        'SAN_LUIS_OBISPO_8.jpg',
        'SAN_LUIS_OBISPO_9.jpg',
        'SAN_LUIS_OBISPO_10.jpg',
        'SAN_LUIS_OBISPO_11.jpg',
        'SAN_LUIS_OBISPO_12.jpg',
        'SAN_LUIS_OBISPO_13.jpg',
        'SAN_LUIS_OBISPO_14.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('11/20/2020'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 35.2828,
    lng: -120.6596,
  },
  trips,
};
