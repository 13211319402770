import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'ojai';

const header = (date) => ({
  heading: 'Ojai, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '3/14/20',
    stories: [
      buildIntro({
        title: 'Ojai, CA',
        description: `A last minute adventure to a sleepy farm town - our own little shelter in place, before it all began.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        { fileName: 'OJAI_1.jpg' },
        { fileName: 'OJAI_2.jpg' },
        { fileName: 'OJAI_3.jpg' },
        { fileName: 'OJAI_4.jpg' },
        { fileName: 'OJAI_5.jpg' },
        { fileName: 'OJAI_6.jpg' },
        { fileName: 'OJAI_7.jpg' },
        { fileName: 'OJAI_8.jpg' },
        { fileName: 'OJAI_9.jpg' },
        { fileName: 'OJAI_11.jpg', seeMore: 'biker babe!' },
        {
          fileName: 'OJAI_12.jpg',
          seeMore:
            'One of the bajillion things you tried on at that little vintage shop next to Farmer and the Cook. (my favorite of the bunch :))',
        },
        { fileName: 'OJAI_10.jpg', seeMore: 'book store finds 📘' },
        { fileName: 'OJAI_13.jpg', seeMore: 'the girl behind the lens 📷' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('3/14/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '9/5/20',
    stories: [
      buildIntro({
        title: 'Ojai, CA',
        description: `Our second Ojai trip. This time to a palm tree oasis for your mom's birthday.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        { fileName: 'OJAI_14.jpg' },
        { fileName: 'OJAI_15.jpg' },
        { fileName: 'OJAI_16.jpg' },
        { fileName: 'OJAI_17.jpg' },
        { fileName: 'OJAI_18.jpg' },
        { fileName: 'OJAI_19.jpg' },
        { fileName: 'OJAI_20.jpg' },
        { fileName: 'OJAI_21.jpg' },
        { fileName: 'OJAI_22.jpg' },
        { fileName: 'OJAI_23.jpg' },
        { fileName: 'OJAI_24.jpg' },
        { fileName: 'OJAI_25.jpg' },
        { fileName: 'OJAI_26.jpg' },
        { fileName: 'OJAI_27.jpg' },
        { fileName: 'OJAI_28.jpg' },
        { fileName: 'OJAI_29.jpg' },
        { fileName: 'OJAI_30.jpg' },
        { fileName: 'OJAI_31.jpg' },
        { fileName: 'OJAI_32.jpg' },
        { fileName: 'OJAI_33.jpg' },
        { fileName: 'OJAI_34.jpg' },
        { fileName: 'OJAI_35.jpg' },
        { fileName: 'OJAI_36.jpg' },
        { fileName: 'OJAI_37.jpg' },
        { fileName: 'OJAI_38.jpg' },
        { fileName: 'OJAI_39.jpg' },
        { fileName: 'OJAI_40.jpg' },
        { fileName: 'OJAI_41.jpg' },
        { fileName: 'OJAI_MOV_1.mp4' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('9/5/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.4443,
    lng: -119.2529,
  },
  trips,
};
