import React, { useState } from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Stories from 'react-insta-stories';

const StoryButton = styled(Button)`
  :hover {
    background-color: ${(props) => props.hoverColor || null};
  }
`;

const StyledModalDialog = withStyles((theme) => ({
  paperFullWidth: {
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
}))(Dialog);

const CloseButton = styled(Avatar)`
  position: absolute;
  top: 20px;
  right: 12px;
  width: 25px;
  height: 25px;
  background-color: #868181;
  padding-bottom: 4px;
  z-index: 10000;
  font-family: sans-serif;
  padding-left: 1px;
  font-size: 25px;
  line-height: 0px;
  cursor: pointer;
  font-weight: lighter;
  :before {
    content: 'x';
  }
  @media (min-width: 768px) {
    padding-left: 0px;
    padding-bottom: 3px;
  }
`;

const StoryModal = (props) => {
  const { customButtonStyle } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const fullPageImage = useMediaQuery(theme.breakpoints.down('xs'));
  const storyStyles = fullPageImage
    ? { width: 'auto', maxWidth: '100%', height: 'auto', margin: 'auto' }
    : {
        width: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        maxHeight: '750px',
        margin: 'auto',
      };
  const buttonStyle = customButtonStyle || {
    display: 'block',
    textTransform: 'capitalize',
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return typeof window !== `undefined` ? (
    <>
      <StoryButton
        color="primary"
        hoverColor={buttonStyle.hoverColor}
        onClick={handleClickOpen}
        style={buttonStyle}
      >
        {props.location}
      </StoryButton>
      <StyledModalDialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Stories
          stories={props.stories}
          defaultInterval={4000}
          width={'100%'}
          height={'100%'}
          storyStyles={storyStyles}
          onAllStoriesEnd={handleClose}
        />
        <CloseButton onClick={handleClose} />
      </StyledModalDialog>
    </>
  ) : null;
};

export default StoryModal;
