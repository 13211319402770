import { buildIntro, buildStory } from '../../../utils';

const location = 'bigBear';

const header = {
  heading: 'Big Bear, CA',
  subheading: 'February 1st, 2020',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '2/1/20',
    stories: [
      buildIntro({
        title: 'Big Bear, CA',
        description:
          'On February 1st we went to Big Bear for our first trip to the snow where I got to see your professional snowboarding abilities 🏂⛄️',
        backgroundColor: 'lightblue',
      }),
      ...[
        'BIG_BEAR_1.jpg',
        'BIG_BEAR_2.jpg',
        'BIG_BEAR_3.jpg',
        'BIG_BEAR_4.jpg',
        'BIG_BEAR_5.jpg',
        'BIG_BEAR_6.jpg',
        'BIG_BEAR_7.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.26,
    lng: -116.92,
  },
  trips,
};
