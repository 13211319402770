import { buildIntro, buildStory } from '../../../utils';

const location = 'palmSprings';

const header = {
  heading: 'Palm Springs, CA',
  subheading: 'December 6th-7th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '12/7/19',
    stories: [
      buildIntro({
        title: 'Palm Springs, CA',
        description: `On December 6th we drove out to Palm Springs to go to my
            friend Trevor's wedding.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        'PALM_SPRINGS_1.jpg',
        'PALM_SPRINGS_2.jpg',
        'PALM_SPRINGS_3.jpg',
        'PALM_SPRINGS_4.jpg',
        'PALM_SPRINGS_5.jpg',
        'PALM_SPRINGS_6.jpg',
        'PALM_SPRINGS_7.jpg',
        'PALM_SPRINGS_12.jpg',
        'PALM_SPRINGS_8.jpg',
        'PALM_SPRINGS_9.jpg',
        'PALM_SPRINGS_10.jpg',
        'PALM_SPRINGS_11.jpg',
        'PALM_SPRINGS_13.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 33.8303,
    lng: -116.5453,
  },
  trips,
};
