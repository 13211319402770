import { buildIntro, buildStory } from '../../../utils';

const location = 'malibu';

const header = (date) => ({
  heading: 'Malibu, CA',
  subheading: 'September 2nd, 2019',
});

const trips = (isMobile, imageEdges) => [
  {
    date: '9/2/2019',
    stories: [
      buildIntro({
        title: 'Malibu, CA',
        description: `On September 2nd we drove to 
          Malibu for a beautiful beach day and 
          snapped one of my favorite photos of you 🏖`,
        backgroundColor: 'lightblue',
      }),
      ...[
        { fileName: 'MALIBU_1.jpg' },
        {
          fileName: 'MALIBU_2.jpg',
          seeMore:
            'Another one of my favorites of you, my beautiful model babe!',
        },
        {
          fileName: 'MALIBU_5.jpg',
          seeMore: 'I was only a litttle bit tanner than you ;)',
        },
        { fileName: 'MALIBU_3.jpg' },
        { fileName: 'MALIBU_4.jpg' },
        { fileName: 'MALIBU_6.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('9/2/2019'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '9/2/2019',
    stories: [
      buildIntro({
        title: 'Malibu, CA',
        description: `My 30th Birthday weekend - beach house style 🏖`,
        backgroundColor: 'lightblue',
      }),
      ...[
        { fileName: 'MALIBU_7.jpg' },
        { fileName: 'MALIBU_8.jpg' },
        { fileName: 'MALIBU_9.jpg' },
        { fileName: 'MALIBU_10.jpg' },
        { fileName: 'MALIBU_11.jpg' },
        { fileName: 'MALIBU_12.jpg' },
        { fileName: 'MALIBU_13.jpg' },
        { fileName: 'MALIBU_14.jpg' },
        { fileName: 'MALIBU_15.jpg' },
        { fileName: 'MALIBU_16.jpg' },
        { fileName: 'MALIBU_17.jpg' },
        { fileName: 'MALIBU_18.jpg' },
        { fileName: 'MALIBU_19.jpg' },
        { fileName: 'MALIBU_20.jpg' },
        { fileName: 'MALIBU_21.jpg' },
        { fileName: 'MALIBU_22.jpg' },
        { fileName: 'MALIBU_23.jpg' },
        { fileName: 'MALIBU_24.jpg' },
        { fileName: 'MALIBU_25.jpg' },
        { fileName: 'MALIBU_26.jpg' },
        { fileName: 'MALIBU_27.jpg' },
        { fileName: 'MALIBU_28.jpg' },
        { fileName: 'MALIBU_29.jpg' },
        { fileName: 'MALIBU_30.jpg' },
        { fileName: 'MALIBU_31.jpg' },
        { fileName: 'MALIBU_32.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('6/19/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0259,
    lng: -118.7798,
  },
  trips,
};
