import React from 'react';

import SeeMore from '../../../components/SeeMore';
import { buildIntro, buildStory } from '../../../utils';

const location = 'carmel';

const header = {
  heading: 'Carmel, CA',
  subheading: 'September 13th-15th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '9/13/19',
    stories: [
      buildIntro({
        title: 'Carmel, CA',
        description:
          'On September 13th we took off from SF to drive down the coast to go glamping in Carmel 🏕',
        backgroundColor: 'lightblue',
        seeMore: ({ close }) => (
          <SeeMore
            close={close}
            text={
              'This was also the 2nd time we had misfortunates with Honey. I think we said at this point we wouldn\'t use the word "adventure" anymore heh ooops!'
            }
          />
        ),
      }),
      ...[
        { fileName: 'CARMEL_18.jpg' },
        { fileName: 'CARMEL_3.jpg' },
        { fileName: 'CARMEL_8.jpg' },
        { fileName: 'CARMEL_9.jpg' },
        { fileName: 'CARMEL_15.jpg' },
        { fileName: 'CARMEL_10.jpg' },
        { fileName: 'CARMEL_2.jpg' },
        { fileName: 'CARMEL_14.jpg' },
        { fileName: 'CARMEL_13.jpg' },
        { fileName: 'CARMEL_12.jpg' },
        {
          fileName: 'CARMEL_20.jpg',
          seeMore: 'Look at all those sheeeep 🐑🐑',
        },
        {
          fileName: 'CARMEL_11.jpg',
          seeMore:
            "One of my favorite photos of you! You look absolutely filled with joy :')",
        },
        { fileName: 'CARMEL_1.jpg' },
        { fileName: 'CARMEL_19.jpg' },
        { fileName: 'CARMEL_16.jpg' },
        { fileName: 'CARMEL_17.jpg' },
        { fileName: 'CARMEL_4.jpg' },
        { fileName: 'CARMEL_5.jpg' },
        { fileName: 'CARMEL_6.jpg' },
        { fileName: 'CARMEL_7.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 36.5552,
    lng: -121.9233,
  },
  trips,
};
