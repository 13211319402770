import React from 'react';

import SeeMore from '../../../components/SeeMore';
import { buildIntro, buildStory } from '../../../utils';

const location = 'brentwood';

const header = {
  heading: 'Brentwood, CA',
  subheading: 'August 16th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '8/16/2019',
    stories: [
      buildIntro({
        title: 'Brentwood, CA',
        description:
          'The first place I ever stayed the night with you. One particular weekend I was down there was August 16th. After meeting all my friends for the first time, we went back to your apartment where...',
        backgroundColor: 'lightblue',
        seeMore: ({ close }) => (
          <SeeMore
            close={close}
            text={
              'I think we officially became boyfriend/girlfriend this weekend 💑'
            }
          />
        ),
      }),
      ...[
        { fileName: 'BRENTWOOD_MOV_1.mp4' },
        {
          fileName: 'BRENTWOOD_1.jpg',
          seeMore:
            "One of the first foot massages I gave you! One day I'll be as good as Pampered ;)",
        },
        { fileName: 'BRENTWOOD_2.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(location, fileName, header, isMobile, imageEdges, seeMore),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0521,
    lng: -118.4732,
  },
  trips,
};
