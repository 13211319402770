import baypointLanding from './baypointLanding/adventure';
import bigBear from './bigBear/adventure';
import bigSur from './bigSur/adventure';
import brentwood from './brentwood/adventure';
import cambria from './cambria/adventure';
import carmel from './carmel/adventure';
import catalina from './catalina/adventure';
import cedarBloom from './cedarBloom/adventure';
import harrisBeach from './harrisBeach/adventure';
import hollywood from './hollywood/adventure';
import lakeTahoe from './lakeTahoe/adventure';
import malibu from './malibu/adventure';
import marinaDelRey from './marinaDelRey/adventure';
import mendocino from './mendocino/adventure';
import newYorkCity from './newYorkCity/adventure';
import ojai from './ojai/adventure';
import orangeCounty from './orangeCounty/adventure';
import palmSprings from './palmSprings/adventure';
import philo from './philo/adventure';
import pointReyes from './pointReyes/adventure';
import sanFrancisco from './sanFrancisco/adventure';
import sanLuisObispo from './sanLuisObispo/adventure';
import santaBarbara from './santaBarbara/adventure';
import santaCruz from './santaCruz/adventure';
import santaMonica from './santaMonica/adventure';
import silverLake from './silverLake/adventure';
import topangaCanyon from './topangaCanyon/adventure';
import venice from './venice/adventure';
import yosemite from './yosemite/adventure';

const adventures = [
  baypointLanding,
  bigBear,
  bigSur,
  brentwood,
  cambria,
  carmel,
  catalina,
  cedarBloom,
  harrisBeach,
  hollywood,
  lakeTahoe,
  malibu,
  marinaDelRey,
  mendocino,
  newYorkCity,
  ojai,
  orangeCounty,
  palmSprings,
  philo,
  pointReyes,
  sanFrancisco,
  sanLuisObispo,
  santaBarbara,
  santaCruz,
  santaMonica,
  silverLake,
  topangaCanyon,
  venice,
  yosemite,
];

export default adventures;
