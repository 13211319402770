import { buildIntro, buildStory } from '../../../utils';

const location = 'silverLake';

const header = {
  heading: 'Silver Lake, CA',
  subheading: 'June 23rd, 2020',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '6/23/2020',
    stories: [
      buildIntro({
        title: 'Silver Lake, CA',
        description: `On June 23rd we rented a room at a chic hotel in Silver Lake to celebrate my birthday proper. We picked up dinner and ate at the reservoir.`,
        backgroundColor: '#f5d142',
      }),
      ...[
        'SILVER_LAKE_1.jpg',
        'SILVER_LAKE_2.jpg',
        'SILVER_LAKE_3.jpg',
        'SILVER_LAKE_4.jpg',
        'SILVER_LAKE_5.jpg',
        'SILVER_LAKE_6.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0869,
    lng: -118.2702,
  },
  trips,
};
