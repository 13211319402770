import React from 'react';
import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';
import SeeMore from '../../../components/SeeMore';

const location = 'santaMonica';

const header = (date) => ({
  heading: 'Santa Monica, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '11/1/2019',
    stories: [
      buildIntro({
        title: 'Santa Monica, CA',
        description: `On November 1st we stayed at a cute little AirBnB in Santa
          Monica, and dressed as tourists for a fun Halloween party 👻`,
        backgroundColor: 'pink',
        seeMore: ({ close }) => (
          <SeeMore close={close} text={'We said I love you here ❤️'} />
        ),
      }),
      ...[
        'SANTA_MONICA_4.jpg',
        'SANTA_MONICA_3.jpg',
        'SANTA_MONICA_1.jpg',
        'SANTA_MONICA_2.jpg',
        'SANTA_MONICA_6.jpg',
        'SANTA_MONICA_5.jpg',
        'SANTA_MONICA_7.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('11/1/2019'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
  {
    date: '10/1/2020',
    stories: [
      buildIntro({
        title: 'Santa Monica, CA',
        description: `Our last day at your studio! And the walk to the famous airsteam.`,
        backgroundColor: 'pink',
      }),
      ...[
        { fileName: 'SANTA_MONICA_8.jpg' },
        { fileName: 'SANTA_MONICA_9.jpg' },
        { fileName: 'SANTA_MONICA_10.jpg' },
        { fileName: 'SANTA_MONICA_11.jpg' },
        { fileName: 'SANTA_MONICA_12.jpg' },
        { fileName: 'SANTA_MONICA_13.jpg' },
        { fileName: 'SANTA_MONICA_14.jpg' },
        { fileName: 'SANTA_MONICA_15.jpg' },
        { fileName: 'SANTA_MONICA_16.jpg' },
        {
          fileName: 'SANTA_MONICA_17.jpg',
          seeMore: 'The hammock we never saw anyone in...',
        },
        { fileName: 'SANTA_MONICA_18.jpg' },
        { fileName: 'SANTA_MONICA_19.jpg' },
        { fileName: 'SANTA_MONICA_20.jpg' },
        { fileName: 'SANTA_MONICA_26.jpg' },
        { fileName: 'SANTA_MONICA_21.jpg' },
        { fileName: 'SANTA_MONICA_22.jpg' },
        { fileName: 'SANTA_MONICA_23.jpg' },
        { fileName: 'SANTA_MONICA_24.jpg' },
        { fileName: 'SANTA_MONICA_25.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('10/1/2020'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 34.0195,
    lng: -118.4912,
  },
  trips,
};
