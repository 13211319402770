import { buildIntro, buildStory } from '../../../utils';

const location = 'marinaDelRey';
const header = {
  heading: 'Marina Del Rey, CA',
  subheading: 'August 18th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '8/18/2019',
    stories: [
      buildIntro({
        title: 'Marina Del Rey, CA',
        description: `On August 18th, one of the weekends I flew down to LA, your
          friend took us out on his sailboat out of the harbor at Marina
          Del Rey.`,
        backgroundColor: '#42f5e3',
      }),
      ...[
        'MARINA_DEL_REY_1.jpg',
        'MARINA_DEL_REY_2.jpg',
        'MARINA_DEL_REY_3.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 33.9803,
    lng: -118.4517,
  },
  trips,
};
