import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'mendocino';

const header = (date) => ({
  heading: 'Mendocino, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '1/2/21',
    stories: [
      buildIntro({
        title: 'Mendocino, CA',
        description: `Our first night in the van!`,
        backgroundColor: 'lightblue',
      }),
      ...[
        'MENDOCINO_1.jpg',
        'MENDOCINO_2.jpg',
        'MENDOCINO_3.jpg',
        'MENDOCINO_4.jpg',
        'MENDOCINO_5.jpg',
        'MENDOCINO_6.jpg',
        'MENDOCINO_7.jpg',
        'MENDOCINO_8.jpg',
        'MENDOCINO_8.jpg',
        'MENDOCINO_10.jpg',
        'MENDOCINO_11.jpg',
        'MENDOCINO_12.jpg',
        'MENDOCINO_13.jpg',
        'MENDOCINO_14.jpg',
        'MENDOCINO_15.jpg',
        'MENDOCINO_16.jpg',
        'MENDOCINO_17.jpg',
      ].map((fileName) =>
        buildStory(
          location,
          fileName,
          header('1/2/2021'),
          isMobile,
          imageEdges,
        ),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 39.3778,
    lng: -123.8136,
  },
  trips,
};
