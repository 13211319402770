import React from 'react';

const SeeMore = ({ close, text }) => (
  <div
    style={{
      maxWidth: '100%',
      height: '100%',
      padding: 40,
      background: 'pink',
      textAlign: 'center',
    }}
  >
    <h2 style={{ marginTop: '50%' }}>{text}</h2>
    <p
      style={{
        textDecoration: 'underline',
        textAlign: 'center',
        bottom: '10px',
        left: '45%',
        position: 'absolute',
        cursor: 'pointer',
      }}
      onClick={close}
    >
      close
    </p>
  </div>
);

export default SeeMore;
