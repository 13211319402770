import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

import { isLoggedIn } from '../services/auth';
import Login from '../components/Login';
import Layout from '../components/layout';
import MapView from '../components/MapView';
import ListView from '../components/ListView';
import SEO from '../components/seo';
import adventures from '../constants/adventures';
import { splitLocationName } from '../utils';

const DateListViewWrapper = styled.div`
  flex-basis: 16%;
  order: 1;
  height: 95vh;
  overflow-x: scroll;
  @media (max-width: 767px) {
    flex-basis: 100%;
    order: 2;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-basis: 26%;
    height: 93vh;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 95vh;
  }
`;

const MapViewWrapper = styled.div`
  height: 95vh;
  flex-basis: 84%;
  order: 2;
  @media (max-width: 767px) {
    flex-basis: 100%;
    height: 60vh;
    order: 1;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-basis: 74%;
    height: 93vh;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 95vh;
  }
`;

export const pageQuery = graphql`
  query PhotographyPostsQuery {
    allS3ImageAsset {
      edges {
        node {
          id
          Key
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const imageEdges = data.allS3ImageAsset.edges;
  const [filteredAdventures, setFilteredAdventures] = useState(adventures);

  const filterAdventuresFromSearchFilter = (searchFilter) =>
    adventures.filter((adventure) =>
      splitLocationName(adventure.location)
        .toLowerCase()
        .includes(searchFilter.toLowerCase()),
    );

  return typeof window !== 'undefined' ? (
    <Layout>
      <SEO title="Adventures of A & M" />
      {isLoggedIn() ? (
        <>
          <DateListViewWrapper>
            <ListView
              adventures={filteredAdventures}
              isMobile={isMobile}
              imageEdges={imageEdges}
              onFilterInput={(filterInput) =>
                setFilteredAdventures(
                  filterAdventuresFromSearchFilter(filterInput),
                )
              }
            />
          </DateListViewWrapper>
          <MapViewWrapper>
            <MapView
              adventures={filteredAdventures}
              isMobile={isMobile}
              imageEdges={imageEdges}
            />
          </MapViewWrapper>
        </>
      ) : (
        <Login />
      )}
    </Layout>
  ) : null;
};

export default IndexPage;
