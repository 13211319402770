import moment from 'moment';
import { buildIntro, buildStory } from '../../../utils';

const location = 'sanFrancisco';

const header = (date) => ({
  heading: 'San Francisco, CA',
  subheading: moment(date).format('MMMM Do YYYY'),
});

const trips = (isMobile, imageEdges) => [
  {
    date: '3/23/2019',
    stories: [
      buildIntro({
        title: 'San Francisco, CA',
        description: `Our San Francisco days, from when I first met you to our many
          adventures since.`,
        backgroundColor: '#fa795c',
      }),
      ...[
        { fileName: 'SAN_FRANCISCO_1.jpg', seeMore: 'Our first texts!' },
        { fileName: 'SAN_FRANCISCO_2.jpg' },
      ].map(({ fileName, seeMore }) =>
        buildStory(
          location,
          fileName,
          header('3/23/2019'),
          isMobile,
          imageEdges,
          seeMore,
        ),
      ),
    ],
  },
  {
    date: '5/25/2019',
    stories: [
      { fileName: 'SAN_FRANCISCO_3.jpg', seeMore: 'The 2nd time I met you :)' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('5/25/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '8/9/2019',
    stories: [
      { fileName: 'SAN_FRANCISCO_4.jpg', seeMore: 'My festival babe 👩‍❤️‍💋‍👨' },
      { fileName: 'SAN_FRANCISCO_5.jpg' },
      { fileName: 'SAN_FRANCISCO_6.jpg' },
      {
        fileName: 'SAN_FRANCISCO_7.jpg',
        seeMore: 'Before I got sick at The Lumineers 😅',
      },
      { fileName: 'SAN_FRANCISCO_8.jpg' },
      { fileName: 'SAN_FRANCISCO_21.jpg' },
      { fileName: 'SAN_FRANCISCO_10.jpg' },
      { fileName: 'SAN_FRANCISCO_9.jpg' },
      { fileName: 'SAN_FRANCISCO_11.jpg' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('8/9/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '8/25/2019',
    stories: [
      'SAN_FRANCISCO_12.jpg',
      'SAN_FRANCISCO_13.jpg',
      'SAN_FRANCISCO_14.jpg',
    ].map((fileName) =>
      buildStory(location, fileName, header('8/25/2019'), isMobile, imageEdges),
    ),
  },
  {
    date: '9/25/2019',
    stories: [
      {
        fileName: 'SAN_FRANCISCO_15.jpg',
        seeMore:
          'I was so surprised and so happy when I found this in the mail from you',
      },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('9/25/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '10/13/2019',
    stories: [
      { fileName: 'SAN_FRANCISCO_16.jpg', seeMore: 'My burrito!!' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('10/13/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '11/16/2019',
    stories: [
      { fileName: 'SAN_FRANCISCO_17.jpg', seeMore: 'Check out that booooty!' },
      { fileName: 'SAN_FRANCISCO_18.jpg' },
      { fileName: 'SAN_FRANCISCO_19.jpg' },
      { fileName: 'SAN_FRANCISCO_20.jpg', seeMore: 'Twinnnnning!' },
      { fileName: 'SAN_FRANCISCO_22.jpg' },
      { fileName: 'SAN_FRANCISCO_23.jpg' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('11/16/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
  {
    date: '12/31/2019',
    stories: [
      { fileName: 'SAN_FRANCISCO_24.jpg' },
      { fileName: 'SAN_FRANCISCO_25.jpg' },
      { fileName: 'SAN_FRANCISCO_26.jpg' },
      { fileName: 'SAN_FRANCISCO_27.jpg' },
      { fileName: 'SAN_FRANCISCO_28.jpg' },
      { fileName: 'SAN_FRANCISCO_29.jpg' },
      { fileName: 'SAN_FRANCISCO_30.jpg' },
      { fileName: 'SAN_FRANCISCO_31.jpg' },
      { fileName: 'SAN_FRANCISCO_MOV_1.mp4' },
      { fileName: 'SAN_FRANCISCO_32.jpg' },
      { fileName: 'SAN_FRANCISCO_33.jpg' },
      { fileName: 'SAN_FRANCISCO_MOV_2.mp4' },
      { fileName: 'SAN_FRANCISCO_MOV_3.mp4' },
    ].map(({ fileName, seeMore }) =>
      buildStory(
        location,
        fileName,
        header('12/31/2019'),
        isMobile,
        imageEdges,
        seeMore,
      ),
    ),
  },
];

export default {
  location,
  coordinates: {
    lat: 37.7692,
    lng: -122.4481,
  },
  trips,
};
