import { buildIntro, buildStory } from '../../../utils';

const location = 'newYorkCity';
const header = {
  heading: 'New York City, NY',
  subheading: 'January 17-20th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '1/17/2020',
    stories: [
      buildIntro({
        title: 'New York City, NY',
        description: `Our first trip out of California to NYC to see the snow and friends! 🍎`,
        backgroundColor: '#42f5e3',
      }),
      ...[
        'NEW_YORK_CITY_1.jpg',
        'NEW_YORK_CITY_MOV_1.mp4',
        'NEW_YORK_CITY_2.jpg',
        'NEW_YORK_CITY_3.jpg',
        'NEW_YORK_CITY_4.jpg',
        'NEW_YORK_CITY_5.jpg',
        'NEW_YORK_CITY_6.jpg',
        'NEW_YORK_CITY_MOVE_2.mp4',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 40.7191,
    lng: -73.9973,
  },
  trips,
};
