import { buildIntro, buildStory } from '../../../utils';

const location = 'santaCruz';

const header = {
  heading: 'Santa Cruz, CA',
  subheading: 'July 29th, 2019',
};

const trips = (isMobile, imageEdges) => [
  {
    date: '7/29/2019',
    stories: [
      buildIntro({
        title: 'Santa Cruz, CA',
        description: `On July 29th I skipped work so we could make up for our Yosemite
          failure and drove down to Santa Cruz!`,
        backgroundColor: '#76c3d6',
      }),
      ...[
        'SANTA_CRUZ_1.jpg',
        'SANTA_CRUZ_2.jpg',
        'SANTA_CRUZ_3.jpg',
        'SANTA_CRUZ_4.jpg',
      ].map((fileName) =>
        buildStory(location, fileName, header, isMobile, imageEdges),
      ),
    ],
  },
];

export default {
  location,
  coordinates: {
    lat: 36.9741,
    lng: -122.0308,
  },
  trips,
};
